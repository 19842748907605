import React from "react"
import Nav from 'react-bootstrap/Nav'
import { FaTwitter, FaInstagram, FaGithub, FaEnvelope, FaLinkedin, FaMediumM } from "react-icons/fa"

const CustomNavbar = ({ pageInfo }) => {
  console.log(pageInfo)
  return (
    <div>
      <Nav as="ul">
        <Nav.Item as="li">
          <Nav.Link className="pl-0" href="https://www.linkedin.com/in/cotterm/">
            <FaLinkedin />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link href="https://github.com/mattcotter">
            <FaGithub />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link href="https://twitter.com/virt">
            <FaTwitter />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link href="https://instagram.com/mattcotter">
            <FaInstagram />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="https://medium.com/@virt">
            <FaMediumM />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="mailto:cotter.m@gmail.com">
            <FaEnvelope />
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  )
}

export default CustomNavbar
