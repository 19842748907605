/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Particles from 'react-particles-js';
import Header from "./header"


const Layout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div>
        <Particles />
        <Container fluid="true" className="m-0 p-0">
          <Row noGutters>
            <Col md="7" lg="4" className="fixed-top">
              <Header siteTitle={data.site.siteMetadata.title} />
            </Col>
          </Row>
        </Container>
        <Container fluid="true">
          <Row noGutters>
            <Col md="7" lg="4"></Col>
            <Col md="5" lg="8" className="position-relative">
              <main>{children}</main>              
            </Col>
          </Row>
        </Container>
      </div>
    )}
  />
)

export default Layout
