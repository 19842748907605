import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navbar from "./navBar"

// images
import myFace from "../images/matt-cotter.jpg"
import mobileLogo from "../images/matt-cotter-logo-r.svg"
import ge from "../images/ge-logo.svg"



const Header = ({ siteTitle }) => (
  <header className="p-3">
    <Link to="/">
      <img src={ myFace } alt="Matt Cotter's Profile Photo" className="my-face" />
      <img src={ mobileLogo } alt="Matt Cotter's Logo (for mobile)" className="mobile-logo" />
    </Link>
      <img src={ myFace } alt="Matt Cotter's Profile Photo (Mobile)" className="my-face-mobile" />
    <h1>Hey! <span role="img" aria-label="Wave Emoji">👋</span></h1>
    <h2>My name is <span>Matt Cotter.</span></h2>
    <p>
      I live in Dayton, OH and I'm a Lead Front End Developer at 
      <span className="work-work-work"><img className="ge-logo" src={ ge } alt="General Electric Logo" />GE Aviation.</span>
    </p>
    <Navbar />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Matt Cotter`,
}

export default Header
